import React from 'react'
import {
  PaginationEndIcon,
  PaginationNextIcon,
  PaginationPrevIcon,
  PaginationStartIcon,
} from './Pagination.icons'
import {PaginationModel} from './Pagination.types'

const Pagination = ({
  onStartPageClick,
  onEndPageClick,
  onNextPageClick,
  onPrevPageClick,
  itemsPerPage = 1,
  pageRange,
  isFirstPage,
  isLastPage,
}: PaginationModel) => {
  return (
    <div className='w-full flex items-center justify-end h-[72px] px-4 py-7'>
      <div className='flex items-center'>
        <div className='text-xs text-neutral-500 mr-7'>Items per page: {itemsPerPage}</div>
        <div className='text-xs text-neutral-500 mr-[60px]'>{pageRange}</div>
        <div className='flex space-x-5'>
          <div className='flex'>
            <button
              disabled={isFirstPage}
              onClick={onStartPageClick}
              className='text-neutral-900 disabled:text-neutral-900/40'
            >
              <PaginationStartIcon />
            </button>
            <button
              disabled={isFirstPage}
              onClick={onPrevPageClick}
              className='text-neutral-900 disabled:text-neutral-900/40'
            >
              <PaginationPrevIcon />
            </button>
          </div>
          <div className='flex'>
            <button
              disabled={isLastPage}
              onClick={onNextPageClick}
              className='text-neutral-900 disabled:text-neutral-900/40'
            >
              <PaginationNextIcon />
            </button>
            <button
              disabled={isLastPage}
              onClick={onEndPageClick}
              className='text-neutral-900 disabled:text-neutral-900/40'
            >
              <PaginationEndIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pagination
