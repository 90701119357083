import React, {FC, useEffect, useRef} from 'react'
import {useLocation} from 'react-router'
import {MasterProvider} from 'src/app/context/MasterContext'
import {PageDataProvider} from 'src/app/context/PageDataContext'
import {HeaderToastProvider} from 'src/app/context/ToastContext'
import {HeaderToast} from '../Libs'
import ContentWrapper from './ContentWrapper'
import ScrollToTop from './ScrollToTop'
import TooltipView from './TooltipView'
import Header from './header/Header.page'
import OverlayWrapper from './overlay/OverlayWrapper'
import Footer from './footer/Footer.page'

const MasterLayout: FC<{children?: React.ReactNode}> = ({children}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <MasterProvider>
      <PageDataProvider>
        <HeaderToastProvider>
          <Header />
          <HeaderToast />
          <OverlayWrapper />
          <ContentWrapper>
              <div ref={scrollRef} className='pt-0 flex flex-col flex-1'>
                <div className='flex flex-1 overflow-auto h-[calc(100vh-80px-116px)]'>
                  <div className='w-full mx-auto'>{children}</div>
                </div>
              </div>
            </ContentWrapper>
          <Footer />
        </HeaderToastProvider>
      </PageDataProvider>
      <ScrollToTop />
      <TooltipView />
    </MasterProvider>
  )
}

export default MasterLayout
