import {usePageData} from 'src/app/context/PageDataContext'
import HeaderPrimary from './Header.primary'
import HeaderSecondary from './Header.secondary'

const Header = () => {
  const {headerType} = usePageData()
  
  switch (headerType) {
    case 'PRIMARY':
      return <HeaderPrimary />
    case 'SECONDARY':
      return <HeaderSecondary />
    default:
      return <HeaderPrimary />
  }
}

export default Header
