import React from 'react'
import { MenuExtraModel } from 'src/app/services/order/Order.model'

type OrderCardMenuExtraProps = {
  data?: MenuExtraModel[]
}

const OrderCardMenuExtra = ({data}: OrderCardMenuExtraProps) => {
  if (data?.length === 0) return null

  const renderListItem = (item: MenuExtraModel, index: number) => {
    return (
      <div key={index} className='flex items-center border-neutral-300 border p-2 rounded bg-neutral-100 '>
        <div className='w-7'>
          <span className='text-sm text-neutral-500 font-medium'>{item.qty} x</span>
        </div>
        <div className='flex-1'>
          <p className='text-sm text-neutral-800 font-medium'>{item.extra_name}</p>
        </div>
      </div>
    )
  }
  return <div className='space-y-1'>{data?.map(renderListItem)}</div>
}

export default OrderCardMenuExtra
