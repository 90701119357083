import {FC, ReactNode} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormError} from '../FormError'
import {
  CheckboxItemProps,
  DotSizes,
  FormCheckboxProps,
  SwitchPlaceholderSizes,
  SwitchPosition,
  SwitchSize,
  SwitchSizes,
} from './Checkbox.types'
import {twMerge} from 'tailwind-merge'

const Container: FC<{children: ReactNode}> = ({children}) => {
  return (
    <div className='flex items-center flex-1'>
      <label className='flex items-center'>{children}</label>
    </div>
  )
}

const Placeholder: FC<{children?: string; sizebox?: SwitchSize; checked?: boolean}> = ({
  children,
  sizebox = 'normal',
  checked = false,
}) => {
  return (
    <div
      className={twMerge(
        [SwitchPlaceholderSizes[sizebox], 'ml-4 font-medium'],
        checked && 'text-primary-500',
        !checked && 'text-neutral-700'
      )}
    >
      {children}
    </div>
  )
}

const ClassicCheckbox: FC<CheckboxItemProps> = ({placeholder, ...rest}) => {
  return (
    <Container>
      <div>
        <input type='checkbox' {...rest} />
      </div>
      {placeholder && <Placeholder>{placeholder}</Placeholder>}
    </Container>
  )
}

const SwitchCheckbox: FC<CheckboxItemProps> = ({placeholder, sizebox = 'normal', ...rest}) => {
  return (
    <Container>
      <div className='flex items-center cursor-pointer'>
        <div className='relative'>
          <input type='checkbox' className='sr-only' {...rest} />
          <div
            className={twMerge([
              'backside rounded-full duration-300',
              SwitchSizes[sizebox],
              rest.checked ? 'bg-primary-500' : 'bg-neutral-300',
            ])}
          ></div>
          <div
            className={twMerge([
              'dot absolute bg-white rounded-full transition duration-200',
              SwitchPosition[sizebox],
              DotSizes[sizebox],
            ])}
          ></div>
        </div>
      </div>
      {placeholder && <Placeholder checked={rest.checked}>{placeholder}</Placeholder>}
    </Container>
  )
}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  className,
  error,
  touched,
  variant = 'switch',
  checkedValue,
  changedValue,
  activeValue,
  inactiveValue,
  height = 'min-h-[44px]',
  ...rest
}) => {
  return (
    <div className={cn('flex items-center', height, className)}>
      {variant === 'classic' && (
        <ClassicCheckbox
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}
      {variant === 'switch' && (
        <SwitchCheckbox
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
