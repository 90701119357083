import React, {useState} from 'react'
import OrderCard from '../components/OrderCard/OrderCard.page'
import Masonry from 'react-responsive-masonry'
import Measure from 'react-measure'
import {PageTitle} from 'src/app/components/Layout/header/Header.title'
import {OrderModel} from 'src/app/services/order/Order.model'
import OrderEmpty from './Order.empty'
import { useOrderPage } from './Order.hook'

const OrderPage = () => {
  const [columnCount, setColumnCount] = useState(1)
  const state = useOrderPage()

  const renderListItem = (item: OrderModel, index: number) => {
    return (
      <Measure key={index}>
        {({measureRef}) => (
          <div ref={measureRef}>
            <OrderCard {...item} key={index} />
          </div>
        )}
      </Measure>
    )
  }

  return (
    <>
      <PageTitle headerType='PRIMARY' description='Order'>
        Order
      </PageTitle>

      <Measure
        bounds
        onResize={(contentRect) => {
          const gutter = 0
          const cardWith = 244
          let screenWidth = contentRect?.bounds?.width ?? 0
          const newColumnCount = (screenWidth - 32) / (cardWith + gutter)
          setColumnCount(Math.floor(newColumnCount))
        }}
      >
        {({measureRef}) => (
          <div className='p-8' ref={measureRef}>
            {state.order?.data?.length === 0 ? (
              <OrderEmpty />
            ) : (
              <Masonry columnsCount={columnCount} gutter='32px'>
                {state?.order?.data?.map(renderListItem) ?? <div />}
              </Masonry>
            )}
          </div>
        )}
      </Measure>
    </>
  )
}

export default OrderPage
