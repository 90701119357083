import React, {MouseEventHandler} from 'react'
import {GButton} from 'src/app/components/Libs'

type SettingsButtonProps = {
  onClick?: MouseEventHandler
}

const SettingsButton = ({onClick}: SettingsButtonProps) => {
  return (
    <GButton
      variant='FILLED'
      size={'large'}
      startIcon='IconCurvedSetting'
      className='bg-primary-50 text-primary-500 border-primary-50'
      onClick={onClick}
    >
      Settings
    </GButton>
  )
}

export default SettingsButton
