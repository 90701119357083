import {FC} from 'react'

const TableNotFoundSearch: FC = () => {
  return (
    <div className='min-h-[278px] flex items-center justify-center flex-col'>
      <h2 className='font-semibold text-xl text-neutral-600'>Pencarian Tidak Ditemukan</h2>
      <p className='mt-4 text-sm text-neutral-500'>Silakan ubah keyword atau reset pencarian</p>
    </div>
  )
}

export default TableNotFoundSearch
