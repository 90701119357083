import {FC} from 'react'
import {Tooltip} from 'react-tooltip'

const TooltipView: FC = () => {
  return (
    <>
      <Tooltip id='view-tooltip' className='tooltip-custom'>
        View
      </Tooltip>
      <Tooltip id='edit-tooltip' className='tooltip-custom'>
        Edit
      </Tooltip>
      <Tooltip id='delete-tooltip' className='tooltip-custom'>
        Delete
      </Tooltip>
      <Tooltip id='send-tooltip' className='tooltip-custom'>
        Send
      </Tooltip>
      <Tooltip id='duplicate-tooltip' className='tooltip-custom'>
        Duplicate
      </Tooltip>
      <Tooltip id='refresh-tooltip' className='tooltip-custom'>
        Refresh
      </Tooltip>
    </>
  )
}

export default TooltipView
