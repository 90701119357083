import {Mutex} from 'async-mutex'
import axios, {AxiosStatic} from 'axios'

const mutex = new Mutex()
const plainAxios = axios.create()

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL

  localAxios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )

  localAxios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const {
        auth: {refreshToken},
      } = store.getState()

      const {status} = error.response ?? {}
      if (status === 401) {
        return mutex.runExclusive(async () => {
          const refresh = await _refreshToken(refreshToken)

          if (refresh) {
            error.config.headers.Authorization = `Bearer ${store.getState().auth.accessToken}`
            try {
              return await plainAxios.request(error.config)
            } catch (err: any) {
              if (err.response?.status === 401) 
              throw err
            }
          }
          throw error
        })
      }
      throw error
    }
  )

  const _refreshToken = async (refreshToken: any) => {
    try {
      const result = await plainAxios.post(`/api/v1/admins/login/refresh-token`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      if (result.data)
      return result.data
    } catch (error) {
      throw error
    }
  }
}
