import React from 'react'
import {Card} from 'src/app/components/Libs'
import {useHistoryPage} from './History.hook'
import Table from '../components/Table/Table.page'
import Pagination from '../components/Pagination/Pagination.page'
import Search from '../components/Search/Search.page'
import {PageTitle} from 'src/app/components/Layout/header/Header.title'
import TableNotFoundSearch from 'src/app/components/Libs/Table/TableNotFoundSearch'

type HistoryPageProps = {}

const HistoryPage = (props: HistoryPageProps) => {
  const state = useHistoryPage()

  return (
    <>
      <PageTitle headerType='SECONDARY' description='History'>
        History
      </PageTitle>
      <div className='p-8'>
        <Card.Root className='shadow-md'>
          <Card.Body className='py-0'>
            <Search
              value={state.search}
              onChange={(e) =>
                state.setQuery({
                  ...state.query,
                  search: e.target.value,
                })
              }
              onReset={() => state.setQuery({...state.query, search: ''})}
            />
            {state.history.data?.content?.length === 0 ? (
              <TableNotFoundSearch />
            ) : (
              <>
                <Table data={state.history.data?.content} />
                <Pagination
                  itemsPerPage={state.history.data?.pagination?.size}
                  onStartPageClick={state.action.onStartPageClick}
                  onPrevPageClick={state.action.onPrevPageClick}
                  onNextPageClick={state.action.onNextPageClick}
                  onEndPageClick={state.action.onEndPageClick}
                  pageRange={state.pageRange}
                  isFirstPage={state.isFirstPage}
                  isLastPage={state.isLastPage}
                />
              </>
            )}
          </Card.Body>
        </Card.Root>
      </div>
    </>
  )
}

export default HistoryPage
