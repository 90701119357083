import React, {ChangeEventHandler} from 'react'
import {FormText, GButton} from 'src/app/components/Libs'

type SearchProps = {
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onReset?: () => void
}

const Search = ({value = '', ...props}: SearchProps) => {
  return (
    <div className='py-5 px-6 flex space-x-5'>
      <FormText
        placeholder='Search'
        onChange={props.onChange}
        value={value}
        prependInnerIcon='IconSearch'
        className='flex-1'
      />
      <GButton
        startIcon='IconRefresh'
        onClick={props.onReset}
        size={'large'}
        className='h-12 w-[117px] bg-primary-500 text-white disabled:bg-primary-500/40 disabled:text-white'
        disabled={!value}
      >
        Reset
      </GButton>
    </div>
  )
}

export default Search
