import {WS_VIEW_MODE} from './ViewMode.socket'
import {useQuery} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {ViewModeModel} from './ViewMode.model'
import {createContext, useContext} from 'react'
import {useWs} from 'src/app/hooks/ws-hook'

const ViewModeContext = createContext<any>(null)

export const ViewModeProvider = ({children}: any) => {
  const value = useWs({
    url: WS_VIEW_MODE,
  })
  return (
    <ViewModeContext.Provider value={value}>
      {children}
    </ViewModeContext.Provider>
  )
}

// Define a custom hook to access the chat messages context
export const useViewModeContext = () => useContext(ViewModeContext)

export const useGetViewMode = () => {
  return useQuery({
    queryKey: [WS_VIEW_MODE],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: createSelector(
      (state: BaseResponse<Pagination<ViewModeModel[]>>) => state.response_output?.list?.content,
      (data) => data
    ),
  })
}
