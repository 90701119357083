import React from 'react'
import {useTime} from 'react-timer-hook'
import {useSettings} from '../../../modules/Settings/Settings.hook'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {useHistory} from 'react-router-dom'
import {GButton} from '../../Libs'
import SettingsButton from '../../../modules/Settings/Settings.button'
import dayjs from 'dayjs'
import SettingsDrawer from '../../../modules/Settings/Settings.drawer'
import {formatFullTime} from 'src/app/utils/time-utils'

const HeaderPrimary = () => {
  const {seconds, minutes, hours} = useTime()
  const {formattedHours, formattedMinutes, formattedSeconds} = formatFullTime(
    hours,
    minutes,
    seconds
  )
  const settingsState = useSettings()
  const history = useHistory()
  return (
    <>
      <div className='h-[80px] bg-white shadow-sm px-8 py-2 flex items-center'>
        <div className='w-[60px] h-[60px]' onClick={() => history.replace('/')}>
          <img
            src={toAbsoluteUrl('/media/logos/logo-makan-ya.png')}
            className='w-full h-full'
            alt='KDS'
          />
        </div>
        <div className='flex flex-col flex-1 px-7'>
          <h1 className='text-lg font-bold text-neutral-800'>
            Order Display System <span className='mx-1 text-neutral-500'>|</span>{' '}
            <span className='text-neutral-500 font-semibold text-[16px]'>Kitchen</span>
          </h1>
          <p className='text-sm text-primary-600 font-medium'>
            {dayjs().format('dddd, MMMM D, YYYY')}
            <span className='mx-1'>•</span>
            <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
            <span>{formattedSeconds}</span>
          </p>
        </div>
        <div className='flex gap-4 items-center'>
          <GButton
            onClick={() => history.replace('/history')}
            variant='FILLED'
            size={'large'}
            startIcon='IconCurvedTime'
            className='bg-primary-50 text-primary-500 border-primary-50'
          >
            History
          </GButton>
          <SettingsButton onClick={() => settingsState.setIsOpen(true)} />
        </div>
      </div>
      <SettingsDrawer
        isOpen={settingsState.isOpen}
        toggleDrawer={settingsState.action.toggleDrawer}
      />
    </>
  )
}

export default HeaderPrimary
