import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  handleClear?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  clearLabel?: string
  loading?: boolean
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  handleClear,
  positiveLabel,
  negativeLabel,
  clearLabel,
  loading,
}) => {
  return (
    <div className='flex gap-x-3 justify-between items-center w-full px-6 py-5 border-t border-neutral-30'>
      {handleClear ?
        <GButton
          onClick={handleClear}
          type='button'
          variant='OUTLINED'
          className='min-w-[138px] text-sm font-medium'
          disabled={loading}
          data-testid='clear-button'
        >
          {clearLabel ?? 'Clear'}
        </GButton>:
        <>
          <button disabled></button>
        </>
      }
      <div className='flex justify-start gap-3'>
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='min-w-[120px] text-sm font-medium border border-primary-500 text-primary-500'
          disabled={loading}
          data-testid='close-button'
        >
          {negativeLabel ?? 'No'}
        </GButton>

        <GButton
          onClick={handleSuccess}
          className='w-[120px] text-sm font-medium item-end'
          type='button'
          loading={loading}
          disabled={loading}
          data-testid='success-button'
        >
          {positiveLabel ?? 'Yes'}
        </GButton>
      </div>
    </div>
  )
}

export default ModalFooter
