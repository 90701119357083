import React, {useRef, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Swiper as SwiperType} from 'swiper'
import {FreeMode, Navigation, Thumbs} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import 'swiper/css/navigation'
import {twMerge} from 'tailwind-merge'

type OrderCardSwiperProps = {
  data: number[]
  selected?: number
  onChange?: (value: number) => void
}

const MIN_SLIDER_COUNT = 4

const OrderCardSwiper = ({data, selected = 0, onChange}: OrderCardSwiperProps) => {
  const swiperRef = useRef<SwiperType>()
  const [isBeginning, setIsBeginning] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const slidesPerView = data.length < MIN_SLIDER_COUNT ? data.length : 4
  const swiperWidth = data.length < MIN_SLIDER_COUNT ? data.length * 72 : 72 * 4
  const containerWidth = swiperWidth + 184
  const spaceBetween = data.length === 1 ? 0 : 16

  return (
    <div className='relative order-card-swiper' style={{ width: containerWidth }}>
      <Swiper
        spaceBetween={spaceBetween}
        modules={[FreeMode, Thumbs, Navigation]}
        style={{ width: swiperWidth }}
        onBeforeInit={(swiper: SwiperType) => {
          swiperRef.current = swiper
          setIsBeginning(swiper.isBeginning)
          setIsEnd(swiper.isEnd)
        }}
        onSlideChange={(swiper: SwiperType) => {
          setIsBeginning(swiper.isBeginning)
          setIsEnd(swiper.isEnd)
        }}
        slidesPerView={slidesPerView}
      >
        {data?.map((_) => (
          <SwiperSlide key={_}>
            <button
              className={twMerge([
                'w-12 h-12 font-semibold rounded-lg text-xl duration-300 mx-auto',
                _ !== selected && 'bg-neutral-100 text-neutral-700 border-neutral-100',
                _ === selected && 'bg-primary-100 text-primary-500 border-primary-500',
                'hover:bg-primary-100 hover:text-primary-500 hover:border-primary-500',
              ])}
              onClick={() => onChange?.(_)}
            >
              {_}
            </button>
          </SwiperSlide>
        ))}
      </Swiper>

      <button
        disabled={isBeginning}
        onClick={() => swiperRef.current?.slidePrev()}
        className={twMerge(
          'absolute top-1/2 -translate-y-1/2 left-0',
          isBeginning && 'text-neutral-300',
          !isBeginning && 'text-primary-500',
          data.length <= MIN_SLIDER_COUNT && "hidden",
        )}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 40 40'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' fill='#F9FAFB' />
          <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' stroke='#F2F4F7' />
          <path
            d='M23 26L17 20L23 14'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>

      <button
        disabled={isEnd}
        onClick={() => swiperRef.current?.slideNext()}
        className={twMerge(
          'absolute top-1/2 -translate-y-1/2 right-0',
          isEnd && 'text-neutral-300',
          !isEnd && 'text-primary-500',
          data.length <= MIN_SLIDER_COUNT && "hidden",
        )}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 40 40'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' fill='#F9FAFB' />
          <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' stroke='#F2F4F7' />
          <path
            d='M17 26L23 20L17 14'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  )
}

export default OrderCardSwiper
