import {createContext, useCallback, useContext, useEffect, useMemo} from 'react'
import {SettingsModel} from './Settings.types'
import {useDispatch} from 'react-redux'
import SettingsRedux from './Settings.redux'
import {transformStringToObject} from 'src/app/utils/string-utils'

const SettingsContext = createContext<any>(null)

export const SettingsProvider = ({children}: any) => {
  const dispatch = useDispatch()

  const getKdsSettings = useCallback(() => {
    window?.kmpJsBridge?.callNative?.(
      'GetKdsSettings',
      JSON.stringify({message: 'getKdsSettings'}),
      function (result: string) {
        const data = transformStringToObject(result)
        dispatch(SettingsRedux.actions.Update(data))
        console.log('result', result)
      }
    )
  }, [dispatch])

  const saveKdsSettings = useCallback(
    (payload: SettingsModel) => {
      if (window?.kmpJsBridge) {
        window?.kmpJsBridge?.callNative(
          'SaveKdsSettings',
          JSON.stringify(payload),
          function (data: SettingsModel) {
            dispatch(SettingsRedux.actions.Update(data))
          }
        )
      } else {
        dispatch(SettingsRedux.actions.Update(payload))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (window.kmpJsBridge) getKdsSettings()
  }, [getKdsSettings])

  const value = useMemo(
    () => ({
      getKdsSettings,
      saveKdsSettings,
    }),
    [getKdsSettings, saveKdsSettings]
  )

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useSettingsContext = () => useContext(SettingsContext)
