import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {FC, Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider} from './context/LayoutContext'
import {LayoutSplashScreen} from './context/SplashScreenContext'
import {Routes} from './routers/Routes'
import {WebSocketProviders} from './context/WebSocketProviders'
import {SettingsProvider} from './modules/Settings/Settings.provider'
import {QueryParamProvider} from 'use-query-params'
import queryString from 'query-string'
import {ReactRouter5Adapter} from 'use-query-params/adapters/react-router-5'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = {
  basename?: string
}

const App: FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <LayoutProvider>
              <WebSocketProviders>
                <SettingsProvider>
                  <Routes />
                </SettingsProvider>
              </WebSocketProviders>
            </LayoutProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
