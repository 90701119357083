import {useQuery} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse} from 'src/app/models/api.types'
import {createContext, useContext} from 'react'
import {useWs} from 'src/app/hooks/ws-hook'
import {WS_ORDER_BROADCAST} from './Order.socket'

const OrderBroadcastContext = createContext<any>(null)

export const OrderBroadcastProvider = ({children}: any) => {
  const value = useWs({
    url: WS_ORDER_BROADCAST,
  })

  return <OrderBroadcastContext.Provider value={value}>{children}</OrderBroadcastContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useOrderBroadcastContext = () => useContext(OrderBroadcastContext)

export const useGetOrderBroadcast = () => {
  return useQuery({
    queryKey: [WS_ORDER_BROADCAST],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: false,
    select: createSelector(
      (state: BaseResponse<any>) => state.response_output,
      (data) => data
    ),
  })
}
