import { useWebTitle } from 'src/app/hooks/title-hook'
import { useGetOrder } from 'src/app/services/order/Order.provider'

export const useOrderPage = () => {
  useWebTitle('Order')
  const order = useGetOrder()
  return {
    order,
  }
}
