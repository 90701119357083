import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {chipTheme} from './Chip.theme'
import {GChipProps} from './Chip.types'
import GIcon from '../Icon/GIcon'
import {IconSizes} from '../Button/Button.types'
import {twMerge} from 'tailwind-merge'

export const GChip: FC<GChipProps> = ({
  children,
  variant,
  uppercase,
  className,
  startIcon,
  endIcon,
  size,
  iconClassName,
  rounded = "default",
}) => {
  return (
    <div className={cn(chipTheme({variant, size, rounded, className}), {uppercase})}>
      {startIcon && (
        <GIcon
          icon={startIcon}
          className={twMerge([IconSizes[size ?? 'medium'], 'mr-3', iconClassName])}
        />
      )}
      {children}
      {endIcon && (
        <GIcon
          icon={endIcon}
          className={twMerge([IconSizes[size ?? 'medium'], 'ml-3', iconClassName])}
        />
      )}
    </div>
  )
}
