import React from 'react'
import {twMerge} from 'tailwind-merge'

type OrderCardStopWatchProps = {
  className?: string
  minutes?: number
  seconds?: number
}

const OrderCardStopWatchIcon = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0832 10.0001C18.0832 14.2567 14.6323 17.7084 10.3748 17.7084C6.11817 17.7084 2.6665 14.2567 2.6665 10.0001C2.6665 5.74258 6.11817 2.29175 10.3748 2.29175C14.6323 2.29175 18.0832 5.74258 18.0832 10.0001Z'
        stroke='#F2F4F7'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.8673 10.6393L10.0923 10.5776V6.53848'
        stroke='#F2F4F7'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const OrderCardStopWatch = ({
  className,
  minutes = 0,
  seconds = 0,
}: OrderCardStopWatchProps) => {
  const formattedMinutes = minutes?.toString().padStart(2, '0')
  const formattedSeconds = seconds?.toString().padStart(2, '0')
  const time = `${formattedMinutes}:${formattedSeconds}`

  return (
    <div
      className={twMerge(
        'flex items-center justify-center rounded-full px-4 py-1 bg-gradient-black space-x-2 h-8 w-[115px] shadow-sm',
        className
      )}
    >
      <OrderCardStopWatchIcon />
      <span className='text-base text-white font-bold'>{time}</span>
    </div>
  )
}

export default OrderCardStopWatch
