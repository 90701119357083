import dayjs from 'dayjs'
import {OrderType} from 'src/app/services/order/Order.model'

export const getOrderInformation = (tableInfo?: string, salesModeName?: string) => {
  let orderType: OrderType = 'QUICK_SERVICE'
  let orderDescription: string = `Quick Service (${salesModeName})`
  let tableName: string = 'Quick Service'
  if (tableInfo) {
    orderType = 'DINE_IN'
    orderDescription = `Dine In (Dine IN)`
    tableName = `Table: ${tableInfo}`
  }

  return {
    orderType,
    orderDescription,
    tableName,
  }
}

export const getStopWatchOffset = (date: string) => {
  const time = dayjs(date)
  const hours = time.get('hours')
  const minutes = time.get('minutes')

  const date1 = dayjs().set('hour', hours).set('minute', minutes)
  const date2 = dayjs()
  const diff = date2.diff(date1, 'seconds', true)
  const stopwatchOffset = new Date()
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + diff)
  return stopwatchOffset
}