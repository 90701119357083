import { useState } from "react"

export const useSettings = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
}

  return {
    isOpen,
    setIsOpen,
    action:{
      toggleDrawer
    }
  }
}