import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormLabelProps} from './Label.types'

export const FormLabel: FC<FormLabelProps> = ({
  children,
  required,
  optional,
  description,
  className,
  appendIconLabel,
}) => {
  return (
    <div className={cn('flex flex-col', className)} data-testid='form-label-container'>
      <label className='flex-grow pb-1 font-medium text-base text-neutral-700 flex gap-x-1 items-center'>
        {children}
        {appendIconLabel}
        {required && <span className=' text-danger'>*</span>}
        {optional && <span className=' text-neutral-70'>(Opsional)</span>}
      </label>

      {description && (
        <div className='flex items-center pb-1 mt-1'>
          <label className='text-fs-10 text-neutral-70'>{description}</label>
        </div>
      )}
    </div>
  )
}
