import {createContext, useContext} from 'react'
import {WS_UPDATE_ORDER_DONE} from './Order.socket'
import {useMutation} from '@tanstack/react-query'
import {useWs, WsPayload} from 'src/app/hooks/ws-hook'
import {useRefetchOrder} from './Order.provider'

const UpdateOrderDoneContext = createContext<any>(null)

// Define a custom hook to access the chat messages context
export const useUpdateOrderDoneContext = () => useContext(UpdateOrderDoneContext)

export const UpdateOrderDoneProvider = ({children}: any) => {
  const value = useWs({
    url: WS_UPDATE_ORDER_DONE,
  })
  return <UpdateOrderDoneContext.Provider value={value}>{children}</UpdateOrderDoneContext.Provider>
}

export const useUpdateOrderDone = () => {
  const { sendMessage } = useUpdateOrderDoneContext()
  const refetchOrder = useRefetchOrder()

  return useMutation({
    mutationKey: [WS_UPDATE_ORDER_DONE],
    mutationFn: (payload: WsPayload) => sendMessage(payload),
    onSuccess: refetchOrder,
  })
}
