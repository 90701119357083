/**
 * Returns an object with the formatted hours, minutes, and seconds.
 *
 * @param {number} hours - The number of hours.
 * @param {number} minutes - The number of minutes.
 * @param {number} seconds - The number of seconds.
 * @return {{hours: string, minutes: string, seconds: string}} - An object with the formatted hours, minutes, and seconds.
 * @example
 * getFormattedTime(1, 2, 3)
 * result: 01:02:03
 */

export const formatFullTime = (
  hours: number,
  minutes: number,
  seconds: number
): {formattedHours: string; formattedMinutes: string; formattedSeconds: string} => {
  const formattedHours = hours?.toString().padStart(2, '0')
  const formattedMinutes = minutes?.toString().padStart(2, '0')
  const formattedSeconds = seconds?.toString().padStart(2, '0')

  return {
    formattedHours,
    formattedMinutes,
    formattedSeconds,
  }
}
