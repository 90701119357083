import React, {useMemo, useState} from 'react'
import {FormCheckbox, FormLabel} from 'src/app/components/Libs'
import _uniq from 'lodash/uniq'
export type OnChangeSelectAll = (value: boolean) => void
export type SetValue = (
  checked: boolean,
  index: number,
  value: string | number | null | undefined
) => void

export type SelectOption = {
  label: string
  value: number
}

export type SettingsMultipleCheckboxProps = {
  title?: string
  description?: string
  required?: boolean
  optional?: boolean
  options?: SelectOption[]
  value?: number[]
  setValue: (value?: number[]) => void
}

const SettingsMultipleCheckbox = ({
  title,
  description,
  required,
  optional,
  options,
  value = [],
  setValue,
}: SettingsMultipleCheckboxProps) => {
  const selectAll = useMemo(() => value?.length === options?.length, [value, options])

  const onChangeSelectAll = (value: boolean) => {
    if (value) {
      setValue?.(options?.map((item: SelectOption) => item.value))
    } else {
      setValue?.([])
    }
  }

  const onChange = (checked: boolean, selectedValue?: any) => {
    if (checked === true) {
      const uniqValue = _uniq([...value, selectedValue])
      setValue(uniqValue)
    } else {
      setValue(value.filter((item: any) => item !== selectedValue))
    }
  }

  const renderCheckboxItem = (item: SelectOption, index: number) => {
    const checkedValue = item.value ? value?.includes(item.value) : false

    return (
      <FormCheckbox
        checkedValue={checkedValue}
        changedValue={(e: any) => onChange?.(e.target.checked, item?.value)}
        activeValue={true}
        inactiveValue={false}
        placeholder={item?.label ?? ''}
        variant='classic'
        key={index}
      />
    )
  }

  return (
    <div>
      <div className='flex'>
        <FormLabel
          className='flex-1 mt-2'
          description={description}
          required={required}
          optional={optional}
        >
          {title}
        </FormLabel>
        <FormCheckbox
          checkedValue={selectAll}
          changedValue={(e: any) => onChangeSelectAll(e.target.checked)}
          activeValue={true}
          inactiveValue={false}
          placeholder='Select All'
        />
      </div>
      <div className='grid grid-cols-2 gap-x-3 gap-y-3'>{options?.map(renderCheckboxItem)}</div>
    </div>
  )
}

export default SettingsMultipleCheckbox
