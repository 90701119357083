import {createContext, useContext} from 'react'
import {WS_UPDATE_QTY_DONE} from './Order.socket'
import {useMutation} from '@tanstack/react-query'
import {useWs, WsPayload} from 'src/app/hooks/ws-hook'
import {useRefetchOrder} from './Order.provider'

const UpdateQtyDoneContext = createContext<any>(null)

// Define a custom hook to access the chat messages context
export const useUpdateQtyDoneContext = () => useContext(UpdateQtyDoneContext)

export const UpdateQtyDoneProvider = ({children}: any) => {
  const value = useWs({
    url: WS_UPDATE_QTY_DONE,
  })
  return <UpdateQtyDoneContext.Provider value={value}>{children}</UpdateQtyDoneContext.Provider>
}

export const useUpdateQtyDone = () => {
  const {sendMessage} = useUpdateQtyDoneContext()
  const refetchOrder = useRefetchOrder()

  return useMutation({
    mutationKey: [WS_UPDATE_QTY_DONE],
    mutationFn: (payload: WsPayload) => {
      return sendMessage(payload)
    },
    onSuccess: refetchOrder,
  })
}
