import {WS_BRANCH} from './Branch.socket'
import {useQuery} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse, Response} from 'src/app/models/api.types'
import {BranchModel} from './Branch.model'
import {createContext, useContext} from 'react'
import {useWs} from 'src/app/hooks/ws-hook'

const BranchContext = createContext<any>(null)

export const BranchProvider = ({children}: any) => {
  const value = useWs({
    url: WS_BRANCH,
  })
  return <BranchContext.Provider value={value}>{children}</BranchContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useBranchContext = () => useContext(BranchContext)

export const useGetBranch = () => {
  return useQuery({
    queryKey: [WS_BRANCH],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: createSelector(
      (state: BaseResponse<Response<BranchModel>>) => state.response_output?.detail,
      (data) => data
    ),
  })
}
