import {FC, useCallback, useEffect} from 'react'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../Icon/GIcon'
import {toastTheme} from './Toast.theme'
import {GToastProps, ToastVariant} from './Toast.types'
import {iconSchemeFn} from './Toast.utils'

export const Toast: FC<GToastProps> = ({
  data,
  removePageToasts,
  delay = 5000,
  autohide,
  className,
}) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (autohide) {
      timeout = setTimeout(() => {
        removePageToasts(data)
      }, delay)
    }

    return () => timeout && clearTimeout(timeout)
    //eslint-disable-next-line
  }, [autohide, delay, data])

  return (
    <div
      data-testid='gtoast-component'
      className={cn(toastTheme({scheme: data.scheme, className}), {'opacity-100': data.shown})}
    >
      <div className='flex items-center justify-center p-4 break-words'>
        <GIcon className='w-6 h-6 mr-3' icon={iconSchemeFn(data)} />

        <span className='flex-1 font-medium'>{data.text}</span>

        {!autohide && <GIcon
          onClick={() => removePageToasts(data)}
          className='w-5 h-5 cursor-pointer'
          icon='IconClose'
        />}
      </div>
    </div>
  )
}

export const HeaderToast: FC<ToastVariant> = ({variant = 'DASHBOARD'}) => {
  const {removePageToasts, hookPageToasts} = useHeaderToast()
  const {minimize} = useMasterLayout()

  const pageToasts = hookPageToasts(useCallback((data) => data, []))

  const styledVariant = (variant: 'FULL' | 'DASHBOARD') => {
    const dashboardVariant = cn(
      'z-[9999] fixed left-2 bottom-6 w-[540px]',
      // {'lg:left-[40px]': minimize},
      // {'lg:left-[264px]': !minimize}
    )
    const fullVariant = 'z-[9999] fixed right-0 left-0 top-10'

    if (variant === 'DASHBOARD') return dashboardVariant
    if (variant === 'FULL') return fullVariant
  }

  return (
    <div className={styledVariant(variant)}>
      <div className='w-full px-8 mx-auto'>
        {pageToasts
          ?.map((data) => (
            <Toast
              data={data}
              removePageToasts={removePageToasts}
              key={data.timestamp}
              autohide
              delay={3000}
            />
          ))
          .reverse()}
      </div>
    </div>
  )
}
