/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {useWebTitle} from 'src/app/hooks/title-hook'
import {useGetHistory, useHistoryContext} from 'src/app/services/history/History.provider'
import { RootState } from 'src/setup'
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params'

const DEFAULT_SIZE = 10

export const useHistoryPage = () => {
  const settings: any = useSelector<RootState>(({settings}) => settings, shallowEqual)
  useWebTitle('History')
  
  const history = useGetHistory()
  const {payload, sendMessage} = useHistoryContext()

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
  })
  const {search, page} = query

  const maxPage = useMemo(() => {
    const total = history.data?.pagination?.total ?? DEFAULT_SIZE
    const size = history.data?.pagination?.size ?? DEFAULT_SIZE
    return Math.ceil(total / size)
  }, [history.data?.pagination?.total, history.data?.pagination?.size])

  const pageRange = useMemo(() => {
    const totalData = history.data?.pagination?.total ?? DEFAULT_SIZE
    const pageSize = history.data?.pagination?.size ?? DEFAULT_SIZE
    const currentPage = page
    const start = (currentPage - 1) * pageSize + 1
    let end = currentPage * pageSize
    end = end > totalData ? totalData : end

    return `${start} - ${end} of ${totalData}`
  }, [history.data?.pagination?.total, history.data?.pagination?.size, page])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (payload) {
        const newMessage = payload
        newMessage.data.search = search
        newMessage.data.page = 1
        sendMessage(newMessage)
        setQuery({search, page: 1})
      }
    }, 500)

    return () => clearTimeout(debounceTimer)
  }, [search, sendMessage, settings.visit_purpose_id])

  useEffect(() => {
    if (payload) {
      const newMessage = payload
      newMessage.data.search = search
      newMessage.data.page = page
      sendMessage(newMessage)
      setQuery({search, page})
    }
  }, [page, sendMessage, settings.visit_purpose_id])

  const onStartPageClick = () => {
    setQuery({
      ...query,
      page: 1,
    })
  }

  const onEndPageClick = () => {
    setQuery({
      ...query,
      page: maxPage,
    })
  }

  const onPrevPageClick = () => {
    if (page === 1) {
      return
    }

    setQuery({
      ...query,
      page: page - 1,
    })
  }

  const onNextPageClick = () => {
    if (page >= maxPage) {
      return
    }

    setQuery({
      ...query,
      page: page + 1,
    })
  }

  const isFirstPage = page === 1
  const isLastPage = page === maxPage

  return {
    search,
    query,
    setQuery,
    history,
    action: {
      onStartPageClick,
      onEndPageClick,
      onPrevPageClick,
      onNextPageClick,
    },
    pageRange,
    isFirstPage,
    isLastPage
  }
}
