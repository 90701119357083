import {useState} from 'react'
import {useStopwatch} from 'react-timer-hook'
import {OrderModel} from 'src/app/services/order/Order.model'
import {getOrderInformation, getStopWatchOffset} from './OrderCard.utils'
import {useUpdateOrderDone} from 'src/app/services/order/Order.update-order-done.provider'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const variant = {
  DEFAULT: {
    limit: 0,
    background: 'bg-gradient-success',
  },
  FIRST_WARNING: {
    limit: 5,
    background: 'bg-gradient-warning',
  },
  SECOND_WARNING: {
    limit: 10,
    background: 'bg-gradient-danger',
  },
}

const getVariant = (
  currentTime: number,
  timeFirstWarning: number = variant.FIRST_WARNING.limit,
  timeSecondWarning: number = variant.SECOND_WARNING.limit
) => {
  if (currentTime < timeFirstWarning) return variant.DEFAULT.background
  if (currentTime < timeSecondWarning) return variant.FIRST_WARNING.background
  return variant.SECOND_WARNING.background
}

export const useOrderCard = (props: OrderModel) => {
  const settings: any = useSelector<RootState>(({settings}) => settings, shallowEqual)
  const [finishAllModal, setFinishAllModal] = useState<boolean>(false)
  const [printChecker, setPrintChecker] = useState(false)
  const stopwatchOffset = getStopWatchOffset(props.created_at)
  const stopWatch = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  })

  const totalMinutes = stopWatch.hours * 60 + stopWatch.minutes
  const variant = getVariant(
    totalMinutes,
    settings.time_first_warning,
    settings.time_second_warning
  )
  const orderInformation = getOrderInformation(props.table_info, props.sales_mode_name)
  const updateOrderDone = useUpdateOrderDone()
  const {addPageToasts} = useHeaderToast()

  const handleOrderDone = async () => {
    try {
      await updateOrderDone.mutateAsync({
        type: 'POST',
        data: {
          pos_id: props.pos_id,
          is_print_checker: printChecker,
        },
      })
      addPageToasts({
        scheme: 'success',
        text: `All menus on ${orderInformation.tableName} finished`,
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setFinishAllModal(false)
    }
  }

  return {
    finishAllModal,
    setFinishAllModal,
    printChecker,
    setPrintChecker,
    stopWatch,
    variant,
    totalMinutes,
    orderInformation,
    action: {
      handleOrderDone,
    },
  }
}
