import {useQuery} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {VisitPurposeModel} from './VisitPurpose.model'
import {WS_VISIT_PURPOSE} from './VisitPurpose.socket'
import {createContext, useContext} from 'react'
import {useWs} from 'src/app/hooks/ws-hook'

const VisitPurposeContext = createContext<any>(null)

export const VisitPurposeProvider = ({children}: any) => {
  const value = useWs({
    url: WS_VISIT_PURPOSE,
  })
  return <VisitPurposeContext.Provider value={value}>{children}</VisitPurposeContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useVisitPurposeContext = () => useContext(VisitPurposeContext)

export const useGetVisitPurpose = () => {
  return useQuery({
    queryKey: [WS_VISIT_PURPOSE],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: createSelector(
      (state: BaseResponse<Pagination<VisitPurposeModel[]>>) =>
        state.response_output?.list?.content,
      (data) => data
    ),
  })
}
