import React from 'react'

type OrderCardChipProps = {
  tableName: string
}

const OrderCardChip = (props: OrderCardChipProps) => {
  return (
    <div className='bg-black/20 px-2 py-1 rounded-md text-sm text-white'>{props.tableName}</div>
  )
}

export default OrderCardChip
