import React from 'react'
import {useTime} from 'react-timer-hook'
import {useSettings} from '../../../modules/Settings/Settings.hook'
import {useHistory} from 'react-router-dom'
import SettingsButton from '../../../modules/Settings/Settings.button'
import dayjs from 'dayjs'
import SettingsDrawer from '../../../modules/Settings/Settings.drawer'
import {usePageData} from 'src/app/context/PageDataContext'
import {formatFullTime} from 'src/app/utils/time-utils'

const HeaderSecondary = () => {
  const {pageTitle} = usePageData()
  const {seconds, minutes, hours} = useTime()
  const {formattedHours, formattedMinutes, formattedSeconds} = formatFullTime(
    hours,
    minutes,
    seconds
  )

  const settingsState = useSettings()
  const history = useHistory()

  return (
    <>
      <div className='h-[80px] bg-white shadow-sm px-8 py-2 flex items-center'>
        <button
          onClick={() => history.replace('/')}
          className='flex items-center justify-center text-primary-600'
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='mr-2'
          >
            <path
              d='M19 12H5M5 12L12 19M5 12L12 5'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <span className='text-lg font-medium'>{pageTitle}</span>
        </button>

        <div className='flex gap-4 items-center ml-auto'>
          <div className='w-[194px]'>
            <p className='text-sm text-neutral-800 font-medium'>
              {dayjs().format('ddd, DD MMM YYYY')}
              <span className='mx-1'>•</span>
              <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
              <span>{formattedSeconds}</span>
            </p>
          </div>
          <SettingsButton onClick={() => settingsState.setIsOpen(true)} />
        </div>
      </div>
      <SettingsDrawer
        isOpen={settingsState.isOpen}
        toggleDrawer={settingsState.action.toggleDrawer}
      />
    </>
  )
}

export default HeaderSecondary
