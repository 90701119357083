import {shallowEqual, useSelector} from 'react-redux'
import {
  getStationFromSetting,
  getVisitPurposeFromSetting,
} from 'src/app/modules/Settings/Settings.helper'
import {useGetBranch} from 'src/app/services/branch/Branch.provider'
import {useGetStation} from 'src/app/services/station/Station.provider'
import {useGetVisitPurpose} from 'src/app/services/visit-purpose/VisitPurpose.provider'
import {RootState} from 'src/setup'

export const useFooter = () => {
  const settings: any = useSelector<RootState>(({settings}) => settings, shallowEqual)
  const appVersion = process.env.REACT_APP_VERSION
  const branch = useGetBranch()
  const station = useGetStation()
  const visitPurpose = useGetVisitPurpose()
  const visitPurposeSetting = getVisitPurposeFromSetting(settings, visitPurpose.data as any[], 'id')
  const stationSetting = getStationFromSetting(settings, station.data)
  const displayStation = stationSetting?.map((item) => item.name).join(' - ')

  return {
    branch,
    visitPurposeSetting,
    stationSetting,
    appVersion,
    displayStation,
  }
}
