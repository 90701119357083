import useWebSocket, {ReadyState} from 'react-use-websocket'
import _isEmpty from 'lodash/isEmpty'
import {transformStringToObject} from '../utils/string-utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {BaseResponse} from '../models/api.types'
import {useQueryClient} from '@tanstack/react-query'
import {useCallback, useState} from 'react'
import {v4 as uuidv4} from 'uuid'

export type UseWs = {
  url: string
  payload?: WsPayload
  dependencies?: any
}

export type WsPayload = {
  type?: string
  data?: Record<string, any>
}

const URLS_TO_LOG: string[] = []

export const useWs = ({
  url,
  payload = {
    type: 'GET',
  },
  dependencies,
}: UseWs) => {
  const [sendMessagePayload, setSendMessagePayload] = useState<WsPayload>()
  const [isRefetch, setIsRefetch] = useState(false)
  const queryClient = useQueryClient()

  const {sendJsonMessage, readyState} = useWebSocket(url, {
    // onOpen: () => console.log(`${url} connection opened.`),
    // onClose: () => console.log(`${url} connection closed.`),
    shouldReconnect: (closeEvent) => true,
    onMessage: (event: WebSocketEventMap['message']) => {
      const message: BaseResponse<any> = transformStringToObject(event.data)

      if (URLS_TO_LOG.includes(url)) {
        console.group(`message`, {
          url,
          payload,
          sendMessagePayload,
          message,
        })
        console.groupEnd()
      }

      // check if message is not empty
      if (_isEmpty(message)) return

      // check if message is success
      if (message.response_schema?.response_code !== '200') {
        console.group('response error', {
          url,
          payload,
          sendMessagePayload,
          message,
        })
        console.groupEnd()
        return
      }

      queryClient.setQueryData([url], (oldData: any) => {
        // handle broadcast message
        if (message === null) return uuidv4()
        return message
      })
    },
  })

  useDeepCompareEffect(() => {
    if (readyState === ReadyState.OPEN && !!payload) {
      sendJsonMessage(payload)
    }
  }, [readyState, sendJsonMessage, payload, dependencies, isRefetch])

  const sendMessage = useCallback(
    (payload: WsPayload) => {
      if (readyState === ReadyState.OPEN) {
        sendJsonMessage(payload)
        setSendMessagePayload(payload)
      }
    },
    [readyState, sendJsonMessage]
  )

  const refetch = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      setIsRefetch(!isRefetch)
      queryClient.invalidateQueries({queryKey: [url]})
    }
  }, [readyState, isRefetch, queryClient, url])

  return {
    payload,
    sendMessagePayload,
    sendMessage,
    refetch,
  }
}
