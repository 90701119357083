import {createContext, useContext} from 'react'
import {WS_HISTORY_BROADCAST} from './History.socket'
import {useQuery} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse} from 'src/app/models/api.types'
import {useWs} from 'src/app/hooks/ws-hook'

const HistoryBroadcastContext = createContext<any>(null)
export const HistoryBroadcastProvider = ({children}: any) => {
  const value = useWs({
    url: WS_HISTORY_BROADCAST,
  })
  return (
    <HistoryBroadcastContext.Provider value={value}>{children}</HistoryBroadcastContext.Provider>
  )
}

// Define a custom hook to access the chat messages context
export const useHistoryBroadcastContext = () => useContext(HistoryBroadcastContext)

export const useGetHistoryBroadcast = () => {
  return useQuery({
    queryKey: [WS_HISTORY_BROADCAST],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: createSelector(
      (state: BaseResponse<any>) => state.response_output,
      (data) => data
    )
  })
}
