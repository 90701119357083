import {createContext, useContext} from 'react'
import {WS_HISTORY} from './History.socket'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {HistoryModel} from './History.model'
import {useWs, WsPayload} from 'src/app/hooks/ws-hook'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {SettingsModel} from 'src/app/modules/Settings/Settings.types'
import { useGetHistoryBroadcast } from './History.history-broadcast.provider'

const HistoryContext = createContext<any>(null)

export const HistoryProvider = ({children}: any) => {
  const settings = useSelector<RootState>(({settings}) => settings, shallowEqual) as SettingsModel
  const historyBroadcast = useGetHistoryBroadcast()

  const payload: WsPayload = {
    type: 'POST',
    data: {
      visit_purpose_id: settings.visit_purpose_id,
      search: '',
      page: 1
    },
  }

  const value = useWs({
    url: WS_HISTORY,
    payload,
    dependencies: historyBroadcast.dataUpdatedAt ?? {}
  })

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useHistoryContext = () => useContext(HistoryContext)

export const useGetHistory = () => {
  return useQuery({
    queryKey: [WS_HISTORY],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: createSelector(
      (state: BaseResponse<Pagination<HistoryModel[]>>) => state.response_output?.list,
      (data) => data
    ),
  })
}

export const useRefetchHistory = (id?: string) => {
  const {refetch} = useHistoryContext()
  const queryClient = useQueryClient()

  return () => {
    refetch()
    queryClient.invalidateQueries({queryKey: [WS_HISTORY]})
  }
}