import {ComponentProps, ComponentType, ReactNode} from 'react'
import { BranchProvider } from '../services/branch/Branch.provider'
import { StationProvider } from '../services/station/Station.provider'
import { VisitPurposeProvider } from '../services/visit-purpose/VisitPurpose.provider'
import { ViewModeProvider } from '../services/view-mode/ViewMode.provider'
import { OrderProvider } from '../services/order/Order.provider'
import { UpdateQtyDoneProvider } from '../services/order/Order.update-qty-done.provider'
import { UpdateOrderDoneProvider } from '../services/order/Order.update-order-done.provider'
import { HistoryProvider } from '../services/history/History.provider'
import { HistoryBroadcastProvider } from '../services/history/History.history-broadcast.provider'
import { OrderBroadcastProvider } from '../services/order/Order.order-broadcast.provider'
type Providers = [ComponentType<any>, ComponentProps<any>?][]

const CombineProviders = (providers: Providers) =>
  providers.reduce(
    (AccumulatedProviders, [Provider, props = {}]) =>
      ({children}: {children: ReactNode}) =>
        (
          <AccumulatedProviders>
            <Provider {...props}>
              <>{children}</>
            </Provider>
          </AccumulatedProviders>
        ),
    ({children}: {children: ReactNode}) => <>{children}</>
  )

  // Add any other web socket providers here
export const WebSocketProviders = CombineProviders([
  [BranchProvider],
  [StationProvider],
  [VisitPurposeProvider],
  [ViewModeProvider],
  [OrderProvider],
  [OrderBroadcastProvider],
  [UpdateQtyDoneProvider],
  [UpdateOrderDoneProvider],
  [HistoryProvider],
  [HistoryBroadcastProvider],
])
