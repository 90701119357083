import {combineReducers} from 'redux'
import SettingsRedux from 'src/app/modules/Settings/Settings.redux'

import LocalizationRedux from 'src/app/plugins/i18n/localization-redux'

export const rootReducer = () =>
  combineReducers({
    localization: LocalizationRedux.reducer(),
    settings: SettingsRedux.reducer()
  })

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export function* rootSaga() {
}
