import {WS_STATION} from './Station.socket'
import {useQuery} from '@tanstack/react-query'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {StationModel} from './Station.model'
import {createContext, useContext} from 'react'
import {useWs} from 'src/app/hooks/ws-hook'

const StationContext = createContext<any>(null)

export const StationProvider = ({children}: any) => {
  const value = useWs({
    url: WS_STATION,
  })
  return <StationContext.Provider value={value}>{children}</StationContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useStationContext = () => useContext(StationContext)

export const useGetStation = () => {
  return useQuery({
    queryKey: [WS_STATION],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: createSelector(
      (state: BaseResponse<Pagination<StationModel[]>>) => state.response_output?.list?.content,
      (data) => data
    ),
  })
}
