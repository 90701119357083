import {FC, useState} from 'react'
import {FormText} from '../Text/Text.component'
import {FormTextProps} from '../Text/Text.types'

export interface FormPasswordProps extends FormTextProps {
  defaultVisibility?: boolean
}

export const FormPassword: FC<FormPasswordProps> = ({defaultVisibility = false, ...rest}) => {
  const [visible, setVisible] = useState(defaultVisibility)

  return (
    <FormText
      type={visible ? 'text' : 'password'}
      appendInnerIcon={visible ? 'IconEyeOff' : 'IconEyeOn'}
      clickAppendInnerIcon={() => setVisible(!visible)}
      appendInnerClassname='text-neutral-60'
      {...rest}
    />
  )
}
