import dayjs from 'dayjs'
import React from 'react'
import {HistoryModel} from 'src/app/services/history/History.model'
import { addThousandSeparator } from 'src/app/utils/input-utils'

type TableProps = {
  data?: HistoryModel[]
}

const Table = ({data}: TableProps) => {
  const renderRow = (item: HistoryModel, index: number) => {
    return (
      <tr key={index}>
        <td>{dayjs(item.update_at).format("hh:mm:ss")}</td>
        <td>{item.table_number || "-"}</td>
        <td>{item.queue_number}</td>
        <td>{item.sales_number}</td>
        <td>{addThousandSeparator(item.qty.toString())}</td>
        <td>{item.menu_name}</td>
        <td>{item.visit_purpose}</td>
        <td>{item.status}</td>
      </tr>
    )
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Time</th>
          <th>Table</th>
          <th>Queue</th>
          <th>Sales Number</th>
          <th>Qty</th>
          <th>Menu</th>
          <th>Visit Purpose</th>
          <th className='w-[116px]'>Status</th>
        </tr>
      </thead>
      <tbody>{data?.map(renderRow)}</tbody>
    </table>
  )
}

export default Table
