import {SettingsModel} from './Settings.types'

export const getVisitPurposeFromSetting = (
  settingsData: SettingsModel,
  data: any[],
  key: 'id' | 'sales_mode_id'
): any[] => {
  if (data?.length === 0) return []
  if (settingsData?.visit_purpose_id?.length === 0) return []
  const visitPurposeIds = settingsData.visit_purpose_id

  if (visitPurposeIds.length > 0) {
    return data?.filter((item) => visitPurposeIds?.includes(item[key]))
  }

  return data
}

export const getStationFromSetting = (settingsData: SettingsModel, data?: any[]): any[] => {
  if (!data) return []
  const stationsId = settingsData.stations_id

  if (stationsId.length > 0) {
    return data.filter((item) => stationsId?.includes(item.id))
  }

  return data
}
