/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useContext, useMemo, useState} from 'react'
import {HeaderType} from '../components/Layout/header/Header.type'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  headerType?: HeaderType
  setHeaderType: (_headerType: HeaderType) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setHeaderType: (_headerType: HeaderType) => {},
})

const PageDataProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [headerType, setHeaderType] = useState<HeaderType>('PRIMARY')

  const value: PageDataContextModel = useMemo(
    () => ({
      pageTitle,
      setPageTitle,
      pageDescription,
      setPageDescription,
      pageBreadcrumbs,
      setPageBreadcrumbs,
      headerType,
      setHeaderType,
    }),
    [pageTitle, pageDescription, pageBreadcrumbs, headerType, setHeaderType]
  )

  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

export {PageDataProvider, usePageData}
