import React, {useState} from 'react'
import OrderCardMoreButton from './OrderCard.more-button'
import {KDSHistoryItemModel} from 'src/app/services/order/Order.model'
import dayjs from 'dayjs'

type OrderCardHistoryProps = {
  data?: KDSHistoryItemModel[]
}

const DATA_TO_SHOW = 4

const OrderCardHistoryIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 17H9.1L15.1 11.05L12.95 8.9L7 14.85V17ZM15.8 10.35L16.85 9.25C16.95 9.15 17 9.03333 17 8.9C17 8.76667 16.95 8.65 16.85 8.55L15.45 7.15C15.35 7.05 15.2333 7 15.1 7C14.9667 7 14.85 7.05 14.75 7.15L13.65 8.2L15.8 10.35ZM5 21C4.45 21 3.97933 20.8043 3.588 20.413C3.19667 20.0217 3.00067 19.5507 3 19V5C3 4.45 3.196 3.97933 3.588 3.588C3.98 3.19667 4.45067 3.00067 5 3H9.2C9.41667 2.4 9.77933 1.91667 10.288 1.55C10.7967 1.18333 11.3673 1 12 1C12.6333 1 13.2043 1.18333 13.713 1.55C14.2217 1.91667 14.584 2.4 14.8 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.0217 20.805 19.5507 21.0007 19 21H5ZM12 4.25C12.2167 4.25 12.396 4.179 12.538 4.037C12.68 3.895 12.7507 3.716 12.75 3.5C12.75 3.28333 12.679 3.10433 12.537 2.963C12.395 2.82167 12.216 2.75067 12 2.75C11.7833 2.75 11.6043 2.821 11.463 2.963C11.3217 3.105 11.2507 3.284 11.25 3.5C11.25 3.71667 11.321 3.896 11.463 4.038C11.605 4.18 11.784 4.25067 12 4.25Z'
        fill='#F2F4F7'
      />
    </svg>
  )
}

const OrderCardHistory = ({data}: OrderCardHistoryProps) => {
  const [itemsToShow, setItemsToShow] = useState(DATA_TO_SHOW)
  if (data?.length === 0) return null

  const renderListItem = (item: KDSHistoryItemModel, index: number) => {
    return (
      <div
        key={index}
        className='flex text-sm text-white space-x-2 px-4 py-2.5 border-b border-neutral-200/50 '
      >
        <div className='w-7'>{item.qty} x</div>
        <div className='flex-1'>
          <p>{item?.menu_name}</p>
        </div>
        <div>
          <span>{dayjs(item.update_at).format('hh:mm')}</span>
        </div>
      </div>
    )
  }

  return (
    <div className='bg-neutral-500'>
      {/* header */}
      <div className='flex px-3 py-2 space-x-4 items-center border-b border-neutral-200'>
        <div>
          <OrderCardHistoryIcon />
        </div>
        <div className='text-white'>
          <h3 className='text-sm font-semibold'>History</h3>
          <p className='text-xs'>All processed transactions</p>
        </div>
      </div>

      {/* list */}
      <div> {data?.slice(0, itemsToShow)?.map(renderListItem)}</div>

      {data && data.length > DATA_TO_SHOW && (
        <OrderCardMoreButton
          variant='secondary'
          onClickSeeLess={() => setItemsToShow(DATA_TO_SHOW)}
          onClickSeeMore={() => setItemsToShow(data.length)}
        />
      )}
    </div>
  )
}

export default OrderCardHistory
