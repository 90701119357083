import {cva} from 'class-variance-authority'

export const chipTheme = cva(['py-[2px]', 'px-2', 'font-semibold', ' inline-flex items-center'], {
  variants: {
    variant: {
      primary: ['bg-primary-light-2', 'text-primary'],
      success: ['bg-success-light-2', 'text-success'],
      danger: ['bg-danger-light-2', 'text-danger'],
      warning: ['bg-warning-light-2', 'text-warning'],
      info: ['bg-info-light-2', 'text-info'],
      neutral: ['bg-neutral-20', 'text-neutral-60'],
      gray: ['bg-neutral-200', 'text-neutral-600'],
    },
    size: {
      small: ['text-xs', 'py-[2px]', 'px-2'],
      medium: ['text-sm', 'px-4 py-2'],
      large: ['text-md', 'px-5 py-3'],
    },
    rounded:{
      full: "rounded-full",
      default: "rounded"
    }
  },
  compoundVariants: [
    {
      variant: 'success',
    },
  ],
  defaultVariants: {
    variant: 'success',
  },
})
