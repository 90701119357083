import React from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import SettingsForm from './Settings.form'

type SettingsDrawerProps = {
  isOpen?: boolean
  toggleDrawer?: () => void
}

const SettingsDrawer = ({isOpen = false, toggleDrawer}: SettingsDrawerProps) => {
  return (
    <Drawer
      size={392}
      open={isOpen}
      onClose={toggleDrawer}
      direction='right'
      className='transition-transform duration-300'
      lockBackgroundScroll
    >
      <div className='h-screen overflow-y-auto flex flex-col pr-0'>
        <div className='px-6 pt-6'>
          <h2 className='text-2xl font-semibold text-black'>Settings</h2>
        </div>
        <SettingsForm handleClose={toggleDrawer} />
      </div>
    </Drawer>
  )
}

export default SettingsDrawer
