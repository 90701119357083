import {Suspense} from 'react'
import { Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import OrderPage from '../modules/order/page/Order.page'
import HistoryPage from '../modules/history/pages/History.page'
import Error404 from '../modules/error/error404'

export function PublicRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={'/history'} component={HistoryPage} />
        <Route path={'/'} component={OrderPage} />
        <Route path="/*" component={Error404} />
      </Switch>
    </Suspense>
  )
}
