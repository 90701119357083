import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {env} from 'src/app/utils/env-utils'
import {SettingsModel} from './Settings.types'

namespace SettingsRedux {
  const STORAGE_KEY = () => {
    return env.REACT_APP_STORAGE_KEY || 'kds-settings'
  }

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export type FetchingStatusState = 'Dine In' | 'Take Away' | ''

  export const actionTypes = {
    Update: '[Update] Action',
  }

  export const initialSettingsModel: SettingsModel = {
    view_mode: 'Kitchen / Bar',
    visit_purpose_id: [75],
    time_first_warning: 5,
    time_second_warning: 10,
    stations_id: [],
    printing_station_id: [],
  }

  export const reducer = () =>
    persistReducer<SettingsModel, ActionWithPayload<SettingsModel>>(
      {
        storage,
        key: STORAGE_KEY(),
        whitelist: [
          'view_mode',
          'visit_purpose_id',
          'time_first_warning',
          'time_second_warning',
          'stations_id',
          'printing_station_id',
        ],
      },
      (state: SettingsModel = initialSettingsModel, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.Update: {
            return {
              ...state,
              ...action.payload,
            }
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    Update: (payload: SettingsModel) => ({
      type: actionTypes.Update,
      payload,
    }),
  }
}

export default SettingsRedux
