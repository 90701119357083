import {DetailedHTMLProps, InputHTMLAttributes} from 'react'

export type VariantCheckbox = 'classic' | 'switch'

export interface FormCheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string
  error?: string
  touched?: boolean
  placeholder?: string
  variant?: VariantCheckbox
  checkedValue?: boolean
  changedValue?: any
  activeValue?: string | boolean | number
  inactiveValue?: string | boolean | number
  sizebox?: SwitchSize
  height?: string
}

export enum SwitchSizes {
  large = 'w-[56px] h-8',
  normal = 'w-9 h-5',
}

export enum SwitchPosition {
  large = 'left-[5px] top-[3px]',
  normal = 'left-[2px] top-[2px]',
}

export enum DotSizes {
  large = 'w-6 h-6',
  normal = 'w-4 h-4',
}

export enum SwitchPlaceholderSizes {
  large = 'text-[16px]',
  normal = 'text-fs-9',
}

export type SwitchSize = 'large' | 'normal'

export interface CheckboxItemProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string
  sizebox?: SwitchSize
}
