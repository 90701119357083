import OrderCardChip from './OrderCard.chip'
import OrderCardMenu from './OrderCard.menu'
import {twMerge} from 'tailwind-merge'
import OrderCardStopWatch from './OrderCard.stop-watch'
import OrderCardHistory from './OrderCard.history'
import BasicModal from 'src/app/components/Widgets/ModalCollection/BasicModal'
import {useOrderCard} from './OrderCard.hook'
import {FormCheckbox} from 'src/app/components/Libs'
import {OrderModel} from 'src/app/services/order/Order.model'
import dayjs from 'dayjs'
import {cn} from 'src/app/utils/cn-utils'

const OrderCard = (props: OrderModel) => {
  const state = useOrderCard(props)
  return (
    <>
      <div
        className={cn([
          'shadow-md bg-white rounded-2xl overflow-hidden w-[244px] outline outline-4 transition-all duration-300',
          state.finishAllModal ? ' outline-primary-300' : ' outline-transparent',
        ])}
      >
        <div
          className={twMerge(['p-4 min-h-[164px] cursor-pointer', state.variant])}
          onClick={() => state.setFinishAllModal(true)}
        >
          <div className='flex justify-between mb-1.5'>
            <h2 className='text-lg font-semibold text-white'>Queue: {props?.queue}</h2>
            <OrderCardChip tableName={state?.orderInformation?.tableName} />
          </div>
          <div className='border-t border-white/10 py-1.5'>
            <span className='text-white text-sm font-medium'>
              {dayjs(props.created_at).format('hh:mm:ss')}
            </span>
            <span className='text-white/50 mx-2'>|</span>
            <span className='text-white text-sm font-medium'>{props.created_by}</span>
          </div>
          <div className='border-t border-white/10 py-1.5'>
            <span className='text-white text-sm font-medium'>
              {state?.orderInformation?.orderDescription}
            </span>
          </div>
          <div
            className={twMerge(
              'border-t border-white/10  text-white text-xs font-medium py-1.5',
              state.orderInformation.orderType === 'QUICK_SERVICE' ? 'flex space-x-1' : 'hidden'
            )}
          >
            <span>Notes: </span>
            <p>{props.note}</p>
          </div>
        </div>
        <OrderCardStopWatch
          className='mx-auto -mt-[16px] relative z-10'
          minutes={state.totalMinutes}
          seconds={state.stopWatch.seconds}
        />

        <OrderCardMenu posId={props?.pos_id} data={props?.kds_order_items} className='-mt-4' />
        <div
          className={cn([
            'h-1 w-full transition-colors duration-300',
            state.finishAllModal ? 'bg-primary-300' : 'bg-transparent',
          ])}
        ></div>
        <OrderCardHistory data={props.kds_history_items} />
      </div>
      <BasicModal
        show={state.finishAllModal}
        handleSuccess={state.action.handleOrderDone}
        handleClose={() => state.setFinishAllModal(false)}
        header='Are you sure to finish all?'
        positiveLabel='Done'
        negativeLabel='Cancel'
        size='md'
        isBtnCloseHidden
      >
        <FormCheckbox
          checkedValue={state.printChecker}
          activeValue={true}
          inactiveValue={false}
          changedValue={(e: any) => state.setPrintChecker(e.target.checked)}
          variant='switch'
          sizebox={'large'}
          placeholder='Print Checker'
        />
      </BasicModal>
    </>
  )
}

export default OrderCard
