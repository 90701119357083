import React, {useEffect, useState} from 'react'
import {twMerge} from 'tailwind-merge'

type Props = {
  onClickSeeMore?: () => void
  onClickSeeLess?: () => void
  variant?: 'primary' | 'secondary'
}

const theme = {
  primary: 'bg-neutral-100 text-primary-500',
  secondary: 'bg-neutral-550 text-white',
}

const OrderCardMoreButton = ({onClickSeeMore, onClickSeeLess, variant = 'primary'}: Props) => {
  const [seeMore, setSeeMore] = useState(false)

  const handleClick = () => {
    setSeeMore(!seeMore)
  }

  useEffect(() => {
    if (seeMore) {
      onClickSeeMore?.()
    } else {
      onClickSeeLess?.()
    }
  }, [seeMore])

  return (
    <button
      onClick={handleClick}
      className={twMerge([
        ' h-10 w-full flex items-center justify-center underline font-medium text-sm',
        theme[variant],
      ])}
    >
      {seeMore ? 'Lihat Lebih Sedikit' : 'Lihat Semua'}
    </button>
  )
}

export default OrderCardMoreButton
