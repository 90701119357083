import React from 'react'
import { MenuPackageModel } from 'src/app/services/order/Order.model'

type OrderCardMenuPackageProps = {
  data?: MenuPackageModel[]
}

const OrderCardMenuPackage = ({data}: OrderCardMenuPackageProps) => {
  if (data?.length === 0) return null

  const renderMenuPackageItem = (item: MenuPackageModel, index: number) => {
    return (
      <div key={index} className='flex items-center border-neutral-300 border p-2 rounded bg-neutral-100 '>
        <div className='w-7'>
          <span className='text-sm text-neutral-500 font-medium'>{item.qty} x</span>
        </div>
        <div className='flex-1'>
          <p className='text-sm text-neutral-800 font-medium'>{item.package_name}</p>
        </div>
      </div>
    )
  }

  return <div className='space-y-1 mb-2'>{data?.map(renderMenuPackageItem)}</div>
}

export default OrderCardMenuPackage
