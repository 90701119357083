import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import MasterLayout from '../components/Layout/MasterLayout'
import Error404 from '../modules/error/error404'
import {PublicRoutes} from './PublicRoutes'

const Routes: FC = () => {
  return (
    <Switch>
      <MasterLayout>
        <PublicRoutes />
        <Route path='/error' component={Error404} />
      </MasterLayout>
    </Switch>
  )
}

export {Routes}
