import React from 'react'

type Props = {}

const OrderEmpty = (props: Props) => {
  return (
    <div className='flex items-center justify-center bg-app-background h-[calc(100vh-80px-116px)] w-full'>
      <h2 className='text-neutral-600 text-xl font-semibold'>Tidak ada Pesanan</h2>
    </div>
  )
}

export default OrderEmpty
