import clsx from 'clsx'
import React, {FC} from 'react'

const ContentWrapper: FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div
      className={clsx(
        `flex flex-col flex-[1_1_auto] min-w-0 transition-all duration-300 overflow-auto`,
      )}
    >
      {children}
    </div>
  )
}

export default ContentWrapper
