import {FC, MouseEventHandler, ReactNode} from 'react'
import {GModal} from '../../Libs'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'

interface BasicModalProps {
  show: boolean
  handleSuccess: MouseEventHandler
  handleClose: MouseEventHandler
  handleClear?: MouseEventHandler
  header: string
  children?: ReactNode
  clearLabel?: string
  positiveLabel?: string
  negativeLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  isBtnCloseHidden?: boolean
  subHeader?: string
}

const BasicModal: FC<BasicModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  handleClear,
  header,
  positiveLabel,
  negativeLabel,
  clearLabel,
  size = 'md',
  loading,
  children,
  isBtnCloseHidden,
  subHeader,
}) => {
  return (
    <GModal show={show} onHide={handleClose} size={size}>
      <ModalHeader
        header={header}
        handleClose={handleClose}
        isBtnCloseHidden={isBtnCloseHidden}
        subHeader={subHeader}
      />
      {children && <div className='px-6 py-5 min-h-[100px] text-neutral-80 w-full'>{children}</div>}
      <ModalFooter
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        handleClear={handleClear}
        positiveLabel={positiveLabel}
        negativeLabel={negativeLabel}
        clearLabel={clearLabel}
        loading={loading}
      />
    </GModal>
  )
}

export default BasicModal
